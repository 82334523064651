import tippy from 'tippy.js';

document.addEventListener("turbolinks:load", function() {
  let openBuy = function (product) {
    fastspring.builder.clean();
    fastspring.builder.add(product);
    fastspring.builder.checkout();
  };

  tippy('#renamer', {
    content: document.querySelector('#renamer-popover'),
    offset: [0, -75],
    allowHTML: true,
    arrow: true,
    interactiveBorder: 20,
    interactive: true,
    theme: 'light',
    animation: 'scale',
    inertia: true,
    delay: [100, 100],
    onShown() {
      let item = document.querySelector('.buy-now');
      item.onclick = function () {
        openBuy("renamer-6");
      };  
    },
  })

  tippy('#archiver', {
    content: document.querySelector('#archiver-popover'),
    offset: [0, -75],
    allowHTML: true,
    arrow: true,
    interactiveBorder: 20,
    interactive: true,
    theme: 'light',
    animation: 'scale',
    inertia: true,
    delay: [100, 100],
    onShown() {
      let item = document.querySelector('.buy-now');
      item.onclick = function () {
        openBuy("archiver-4");
      };  
    },
  })
});