document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('#contact')) {
    document.querySelector("#contact").addEventListener("submit", function(event) {
      event.preventDefault();
      const url = "https://api.incrediblebee.com/contact-us";
      let form = event.target;
      let data = new FormData(form);
      let queryParams = new URLSearchParams(data).toString();
      let successMessage = document.querySelector(`#contact .success`);
      fetch(`${url}?${queryParams}`).then(function(response) {
        if(response.status == 200) {
          successMessage.classList.remove("hidden");
          form.parentElement.innerHTML = successMessage.innerText
        } else {
          successMessage.classList.add("hidden");
        }
      });
    });
  };
});